import { render, staticRenderFns } from "./workVisaApplication2.vue?vue&type=template&id=85e4d310&scoped=true"
import script from "./workVisaApplication2.vue?vue&type=script&lang=js"
export * from "./workVisaApplication2.vue?vue&type=script&lang=js"
import style0 from "./workVisaApplication2.vue?vue&type=style&index=0&id=85e4d310&prod&lang=scss&scoped=true"
import style1 from "./workVisaApplication2.vue?vue&type=style&index=1&id=85e4d310&prod&lang=scss&scoped=true"
import style2 from "./workVisaApplication2.vue?vue&type=style&index=2&id=85e4d310&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e4d310",
  null
  
)

export default component.exports